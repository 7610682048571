@import '../../assets/styles/all';


@mixin section() {
    padding: 100px 0;
    @include respond-to(mobile-screens) {
        padding: 80px 0;
    }
}

.sectionBlue1{
    @include section();
    background: $b4s-blue-1;
    color: white;
}

.sectionBlue2{
    @include section();
    background: $b4s-blue-2;
    color:white;
}

.sectionBlueSpecial{
    @include section();
    background: url('../../assets/img/home-logos.svg') $b4s-blue-1;
    background-size: cover;
    background-position: top;
    color:white;
}

.head{
    background: url('../../assets/img/home-head-bg.svg') $b4s-blue-1;
    background-size: cover;
    background-position: bottom;
    .headImg{
        width:100%;
    }
    .headLine{
        display: flex;
        flex-direction: row;
        padding:40px 0 0;
        justify-content: space-between;
        align-items: center;
        .logo img{
            height:80px;
        } 
    }
    .headContent{
        display: flex;
        flex-direction: row;
        
        .textHalf{
            flex:70%;
            .headText{
                margin-bottom: 40px;
                h1{@include b4s-h3(); margin-bottom: 20px;}
                p{ @include b4s-body();}
            }
            .buttons{
                display: flex;
                flex-direction: row;
                button{
                    width: 200px;
                }
                button:nth-child(1){
                    margin-right: 40px;
                }
            }
            @include respond-to(tablet-screens) {
                .headText{
                    h1{
                        text-align: center;
                    }
                    p{
                        text-align: center;
                        margin:auto;
                        width: 375px;
                    }
                }
        
                .buttons{
                    flex-wrap: wrap;
                    justify-content: space-evenly;
                    button:nth-child(1){
                        margin:0 0 20px;
                    }
                }
            }
        
            @include respond-to(mobile-screens) {
                .headText{
                    p{
                        width: auto;
                    }
                }
            }
        }
        .imgHalf{
            flex: 30%;
            img{
                height: 450px;
                top: -60px;
                position: relative;
                z-index: 1;
            }
        }
        @include respond-to(small-screens) {
            .imgHalf{
                display: none;
            }
        }
    }

    .spacing100{
        margin-bottom: 0;
        padding-bottom: 150px;
    }

    @include respond-to(mobile-screens) {
        .headLine {
            .logo img {
                height:75px;
            } 
        }
    }

}

.features{
    .featuresBody{
        position:flex;
    }

    .feature{
        display: flex;
        flex-direction:row;
        .featureText{
            flex-basis: 50%;
            flex-shrink: 0;
            .featureTitle{
                @include b4s-h3(white, 600);
                margin-bottom: 15px;
            }
            .featureDesc{
                @include b4s-feature-text();
                text-align: justify;
            }
        }
        .producerImg{
            flex-basis: 50%;
            max-height: 100%;
            max-width: 100%;
            mix-blend-mode: lighten;
            border-radius: 80px 30px;
        }
        .beatsImg{
            flex-basis: 50%;
            max-height: 100%;
            max-width: 100%;
            mix-blend-mode: lighten;
            border-radius: 80px 30px;
        }

        .marginLeft{
            margin-left:100px;
        }

        .marginRight{
            margin-right:100px;
        }
    }

    @include respond-to(medium-screens) {
        ul.featurelist{
            justify-content: space-between;
            li{
                width: 400px;
            }
        }
    }
    
    @include respond-to(small-screens) {
        ul.featurelist{
            justify-content: space-around;
            margin-bottom: 40px;
            li{
                @include b4s-body-small();
                margin-right: 10px;
                width: auto;
                padding: 20px 10px;
                flex: 1;
            }
        }

        .feature{
            margin: 0;
            align-items: center;
            &.productores{
                margin-bottom: 50px;
                flex-direction: column-reverse;
                
                .producerImg{
                    margin:0 0 50px 0;
                }
            }
            &.beats{
                flex-direction: column;
                .beatsImg{
                    margin:0 0 50px 0;
                }
            }
    
            .producerImg{
                height: 250px;
                width: 320px;
            }
    
            .beatsImg{
                height: 250px;
                width: 320px;
            }
        }
    }

    @include respond-to(tablet-screens) {
        ul.featurelist{
            @include b4s-body();
            flex-direction: column;
        }
    }
}

.logos{
    .logoRow1,.logoRow2 {
        display: flex;
        flex-direction: row;
        img{
            height: 125px;
        }
    }
    .logoRow1{
        justify-content: space-between;
        img:nth-child(1){order:2;}
        img:nth-child(2){order:4;}
        img:nth-child(3){order:1;}
        img:nth-child(4){order:5;}
        img:nth-child(5){order:3;}
    }
    .logoRow2{
        justify-content: space-evenly;
    }

    @include respond-to(medium-screens) {
        img{
            height:100px;
            width: 100px;
        }
    }
    

    @include respond-to(tablet-screens) {
        .logoRow1{
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .logoRow2{
            display: none;
        }

        img{
            flex: 1 0 30%;
        }
    }

}

.nuestrosBeats{
    .playerContainer{
        display: flex;
        flex-direction: row;
        .player{
            flex: 1;
        }
        ul.playerFeatures{
            padding-left: 50px;
            list-style: none;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    @include respond-to(medium-screens) {
        .playerContainer{
            .player{
                flex:1;
            }
            ul.playerFeatures{
                padding-left: 20px;
                flex: 1;
            }
        }
    }

    @include respond-to(tablet-screens) {
        .playerContainer{
            flex-direction: column;
            ul.playerFeatures{
                padding:50px 0 0 0;
            }
            li:not(:last-child){
                margin-bottom: 20px;
            }
        }
    }
}

.grandesPesos{
    background: $b4s-blue-2;
    display: flex;
    flex-direction: column;
    padding: 24px 0;

    .fila1{
        background-image: url(../../assets/img/b4s-credits-1.jpg), url(../../assets/img/b4s-credits-3.jpg);
        background-position: center;
        background-repeat: no-repeat, repeat;
        height: 200px;
    }
    .fila2{
        display: flex;
        height:200px;
        padding: 24px 0;
        box-sizing: initial;
        .espacioIzq{
            flex:1;
            background-image: url(../../assets/img/b4s-credits-2.jpg);
            background-position: right;
        }
        .promo{
            display: flex;
            flex-direction: column;
            width:360px;
            padding: 0 43px;
            text-align: center;
            justify-content: space-between;
            h3{@include b4s-h3(white, 500);}
            p{ @include b4s-title(white, 300);}
        
        }
        .espacioDer{
            flex:1;
            background-image: url(../../assets/img/b4s-credits-2.jpg);
            background-position: left;
        }
    }
    .fila3{
        background-image: url(../../assets/img/b4s-credits-3.jpg), url(../../assets/img/b4s-credits-1.jpg);
        background-position: center;
        background-repeat: no-repeat, repeat;
        height: 200px;
    }

    @include respond-to(tablet-screens) {
        .fila2{
            height: 150px;
        }
        .fila1,.fila3{
            height: 150px;
            background-size: 922px 150px;
            background-position: 0px 0px;
            background-repeat: repeat-x;
            animation: animatedBackground 30s linear infinite;
        }
        @keyframes animatedBackground {
            from { background-position: 0 0; }
            to { background-position: 100% 0; }
        }
    }
}

.howContainer{
    display: flex;
    flex-direction: row;
    margin-bottom: 100px;
    .howCard{
        padding: 40px 25px;
        border-radius: 5px;
        margin: 0 10px;
        flex:1;

        &.howCard1{
            background: $b4s-blue-8;
        }
        &.howCard2{
            background: $b4s-purple-2;
        }
        &.howCard3{
            background: $b4s-purple-1;
        }

        h4{
            @include b4s-h4(white, 500);
            margin-bottom: 10px;
        }
    }

    @include respond-to(tablet-screens) {
        &{
            display: flex;
            position: initial;
            margin-bottom: 50px;
            flex-direction: column;
            .howCard{
                padding: 20px 20px;
                margin: 5px 0;
            }
        }
    }
}

.story{
    .body{
        position:flex;
    }

    .desc{
        @include b4s-feature-text();
        text-align: justify;
    }

    .author{
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        @include b4s-body-small(white, 600);
        align-items: center;
        img{
            margin-right: 20px;
            border-radius: 50%;
            height: 64px;
            width: 64px;
        }
        .titles{
            @include b4s-body-small(white, 300);
        }
    }
}

.feedback{
    display:flex;
    flex-direction: row;
    iframe{
        margin-right:20px;
        flex:1;
        height: 215px;
        &:last-child{
            margin:0;
        }
    }
    @include respond-to(medium-screens) {
        iframe{
            height: 175px;
        }
    }
    @include respond-to(small-screens) {
        &{
            display: none;
        }
    }

}

.feedbackCarousel{
    display: none;
    @include respond-to(small-screens) {
        &{
            display: block;
            position:relative;

            .carousel{
                height: 100%;
                .slider{
                    height: 100%;
                    width: 500px;
                    height: 281px;
                    margin: auto;
    
                    iframe{
                        width: 500px;
                        height: 281px;
                    }
                }
                .directionButton{
                    position: absolute;
                    top: 50%;
                    background: 0;
                    border: none;
                    i{
                        font-size: 32px;
                        color: white;
                    }
                }
                .buttonBack{
                    left: 0;
                }
                .buttonNext{
                    right: 0;
                }
            }

        }
    }

    @include respond-to(tablet-screens) {
        .carousel{
            .slider{
                width: 460px;
                iframe{
                    width: 460px;
                }
            }
        }
    }

    @include respond-to(mobile-screens) {
        .carousel{
            .slider{
                width: 100%;
                iframe{
                    width: 100%;
                }
            }
        }
    }
}


.featureLi {
    @include b4s-body();
    background: $b4s-blue-1;
    padding:20px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    &.light{
        background: $b4s-blue-2;
    }
    .sadIcon, img{
        margin-right: 20px;
    }

    .sadIcon{
        font-size: 36px;
    }
}

ul.featurelist{
    list-style: none;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    li{
        width: 480px;
        margin-bottom: 20px;
    }
}
