@import '../../assets/styles/all';

.card{
    width:100%;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    box-shadow: 0 4px 15px 0 rgba(0,0,0,.5);

    .loginCard{
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background: $b4s-grey-1;
        width: 300px;
        display: flex;
        flex-direction: column;
        padding: 30px;

        .titleText{
            @include b4s-body($b4s-blue-3, 500);
            margin-bottom: 20px;
        }

        .step20{
            margin-bottom: 20px;
        }

        .step40{
            margin-bottom: 40px;
        }

        .signForm{
            flex-direction: column;
            display: flex;
        }
    }
    .adCard{
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background: $b4s-blue-2;
        flex: 1;
        padding: 50px;

        h1{
            @include b4s-h2();
            margin-bottom: 50px;
            text-transform: uppercase;
        }

        p{
            @include b4s-body(white, 400);
        }

        .link{
            @include b4s-body(white, 400);
        }
    }

    @include respond-to(small-screens) {
        .loginCard{
            width: 260px;
        }
    }

    @include respond-to(tablet-screens) {
        flex-direction: column;
        .loginCard{
            width:auto;
        }
    }
}

.noPassText{
    @include b4s-body($b4s-blue-2);
    text-align: center;
}
