@import '../../assets/styles/_all';

.title{
  display: flex;
  flex-direction: row;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  &.dark{
    background: $b4s-blue-1;
  }
  .titleText{
    flex: 1;
    h3{
      @include b4s-title(white);
      text-align: justify;
    }
  }

  .expand{
    margin-left: 20px;
    color: white;
  }

}

.content{
  &.dark{
    background: $b4s-blue-1;
  }
  
  li{
    list-style: inside;
  }
}


