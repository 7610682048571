@import '../../assets/styles/_all';

.cookies{
    width:100%;
    min-height:100px;
    position: fixed;
    z-index: 10;
    @include b4s-blue-purple-grad-1();

    .container{
        @include b4s-container();
        padding: 50px 0;
        .title{
            h3{@include b4s-h3();}
            margin-bottom: 20px;
        }
        .twoRows{
            display: flex;
            flex-direction: row;
            .content{
                height: 200px;
                overflow: auto;
                flex: 3;
                h4{
                    @include b4s-h4();
                    margin-top: 30px;
                }
                p{ @include b4s-body();}
                margin-right: 30px;
            }
            .actions{
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                Button:first-child{
                    margin-bottom: 30px;
                }
            }
        }
    }
}

@include respond-to(tablet-screens) {
    .cookies{
        height: 80vh;
        display: flex;
        flex-direction: column;
        .container{
            display: flex;
            flex-direction: column;
            padding: 0;
            flex: 1;
            .title{
                margin-top: 30px;
            }
            .twoRows{
                flex-direction: column;
                flex:1;
                .content{
                    display: flex;
                    flex-direction: column;
                    flex: initial;
                    margin:0;
                    overflow: scroll;
                    min-height: 0;
                    height: 42vh;
                }
                .actions{
                    flex: 1;
                    margin-top:30px;
                }
            }
        }
    }
}
