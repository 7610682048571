@import '../../assets/styles/all';


.background{
    @include b4s-blue-purple-grad-1();
    padding-bottom:100px;
    min-height:50vh;
}

.container{
    @include b4s-container();
}

.headLine{
    display: flex;
    flex-direction: row;
    padding:40px 0 60px;
    justify-content: space-between;
    align-items: center;
    .logo img{
        height:80px;
    } 
}

.content{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.left{
    width: 420px;
    .h2{
        margin-top: 100px;
        @include b4s-h1(white, 600);
    }
    
    .p, .p a{
        @include b4s-body(white);
    }

    .button{
        margin-top: 40px;
    }
}


.img{
    width: 360px;
}

@include respond-to(tablet-screens) {
    .content{
        flex-direction: column-reverse;
        .left{
            width: auto;
            .h2{
                margin-top: 20px;
            }
        }
        .right{
            margin:auto;
            .img{
                width: 200px;
            }
        } 
    }
}