@import '../../assets/styles/all';


.footer{
     background: $b4s-blue-1;
     padding-top:50px;
     padding-bottom:100px;
 }

 .container{
     @include b4s-container();
 }

 .firstRow{
    margin-bottom: 20px;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    .lang{
        position:relative;
    }

    .lang .langIcon{
        color: $b4s-blue-3;
        position:absolute;
        font-size: 20px;
        left: 20px;
        top: 10px;
    }

    .lang .arrowIcon{
        color: $b4s-blue-3;
        position:absolute;
        right: 25px;
        top: 13px;
    }

    .lang select{
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding-left: 50px;
        width: 200px;
        height: 40px;
        background: $b4s-blue-1;
        @include b4s-body-small($b4s-blue-3,700);
        border-radius: 20px;
        border: 1px solid $b4s-blue-3;
        line-height: normal;
        cursor: pointer;
    }
    
    .lang select::-ms-expand {
        display: none;
    }

    .lang select option{
        border: none;
        background: $b4s-blue-2;
        @include b4s-body-small($b4s-blue-3,700);
    }
    
     .logo img{
         height:80px;
     }

     .social{
         display:flex;
         align-items: center;
         @include b4s-body($b4s-blue-3, 700);
         .icon{
             margin:0 20px;
         }
         .icon:before{
             font-size: 32px;
             color: $b4s-blue-3; 
         }
     }
 }



 .hr{
    border : 0;
    height : 1px;
    background : $b4s-blue-3;
    margin : 1em 0;
 }

 .menu{
     display:flex;
     flex-direction: row;
     list-style: none;
     justify-content: space-between;
 }

 .secondRow{
    margin-top: 20px;
 }

 .menuLink a{
    @include b4s-body-small($b4s-blue-3);
    text-decoration: none;
 }

 @include respond-to(tablet-screens) {
     .footer{
         padding-bottom:20px;
     }

     .footer hr{
         margin-bottom:30px;
     }

     .firstRow, .secondRow .menu{
        flex-direction: column;
        align-items: center;
    }

    .firstRow{
        margin-bottom: 0;
    }

    .firstRow *, .secondRow .menu li{
        margin-bottom: 20px;
    }
 }
