/* Font */
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,900');


/* Colours */
$b4s-blue-1: #1b1d27; //Background
$b4s-blue-2: #33385A; //Background / Text on light bg
$b4s-blue-3: #969CC5; //Text on dark bg
$b4s-blue-4: #35508A;
$b4s-blue-5: #041836; //Button
$b4s-blue-6: #66b9df; //Button
$b4s-blue-7: #051937; //Gradient
$b4s-blue-8: #454ebd; //button

$b4s-gold-1: #D1BA7B;

$b4s-grey-1: #F3F4F9; //Background

$b4s-pink-1: #FF7DCE; //Button
$b4s-pink-2: #EC6BD6; //Button
$b4s-pink-3: #C261C4; //Gradient
$b4s-pink-4: #FF8ADF; //Button border
$b4s-pink-5: #EC74DC; //Shadow
$b4s-pink-6: #FFADD7; //Button
$b4s-pink-7: #FD33BC; //Button
$b4s-pink-8: #f7deef; //Button

$b4s-purple-1: #3C358A; //Gradient/Button
$b4s-purple-2: #3C2BB1; //Gradient
$b4s-purple-3: #8731BC; //Gradient

$b4s-green-1: #def7e5; //Success Alert background
$b4s-green-2: #39D756; //Success Alert Border


/* Gradients */

//Buttons
@mixin b4s-pink-grad-1($opac:1) {
    background: linear-gradient(to right, rgba($b4s-pink-1, $opac) 0%, rgba($b4s-pink-2, $opac) 100%);
}

//icons
@mixin b4s-pink-grad-2() {
    background: linear-gradient(to right, $b4s-pink-6 0%, $b4s-pink-7 100%);
}

//Buttons
@mixin b4s-blue-grad-1() {
    background: linear-gradient(to right, $b4s-blue-4 0%, $b4s-purple-1 100%);
}

@mixin b4s-cyan-grad-1() {
    background: linear-gradient(to right, $b4s-blue-6 0%, $b4s-blue-8 100%);
}

//Background
@mixin b4s-blue-purple-grad-1($opac:1) {
    background: linear-gradient(to right, rgba($b4s-blue-5, $opac) 0%, rgba($b4s-purple-1, $opac) 100%);
}

/* Layour */
$break-s: 480px;
$break-sm: 720px;
$break-md: 992px;
$break-lg: 1260px;
@mixin respond-to($media) {
    @if $media == mobile-screens {
        @media only screen and (max-width: $break-s) { @content; }
    }      
    @else if $media == tablet-screens {
        @media only screen and (max-width: $break-sm) { @content; }
    } 
    @else if $media == small-screens {
        @media only screen and (max-width: $break-md) { @content; }
    }
    @else if $media == medium-screens {
        @media only screen and (max-width: $break-lg) { @content; }
    }
    @else if $media == wide-screens {
        @media only screen and (min-width: $break-lg) { @content; }
  }
}

@mixin b4s-container(){
    margin:auto;
    @include respond-to(wide-screens) { width:1200px; }
    @include respond-to(medium-screens) { width: 942px; }
    @include respond-to(small-screens) { width: 90%; }
    @include respond-to(tablet-screens) { width: 95%; }
    @include respond-to(mobile-screens) { width:95%;}
}


/* Text */
@mixin b4s-button-text($color:white){
    font-family: 'Poppins';
    font-size: 16px;
    letter-spacing: 1.4px;
    color: $color;
    @include respond-to(medium-screens) { font-size: 14px }
}

@mixin b4s-h1($color:white, $weight: 700){
    font-family: 'Poppins';
    font-size: 38px;
    font-weight: $weight;
    letter-spacing: -0.35px;
    color: $color;
    @include respond-to(small-screens) { font-size: 32px }
    @include respond-to(tablet-screens) { font-size: 26px }
}

@mixin b4s-h2($color:white, $weight:700){
    font-family: 'Poppins';
    font-size: 32px;
    font-weight: $weight;
    letter-spacing: -0.35px;
    color: $color;
    @include respond-to(small-screens) { font-size: 28px }
    @include respond-to(tablet-screens) { font-size: 22px }

}

@mixin b4s-h3($color:white, $weight:700){
    font-family: 'Poppins';
    font-size: 28px;
    font-weight:$weight;
    letter-spacing: -0.33px;
    color: $color;
    @include respond-to(small-screens) { font-size: 24px }
    @include respond-to(tablet-screens) { font-size: 20px }
}

@mixin b4s-h4($color:white, $weight:400){
    font-family: 'Poppins';
    font-size: 24px;
    font-weight:$weight;
    letter-spacing: -0.33px;
    color: $color;
    @include respond-to(small-screens) { font-size: 20px }
    @include respond-to(tablet-screens) { font-size: 18px }
}

@mixin b4s-feature-text($color:white, $weight:400){
    font-family: 'Poppins';
    font-size: 16px;
    line-height: 28px;
    font-weight: $weight;
    color: $color;
    @include respond-to(tablet-screens) { font-size: 14px; line-height: 22px; }
}

@mixin b4s-title($color:white, $weight:600){
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: $weight;
    letter-spacing: -0.35px;
    color: $color;
    @include respond-to(tablet-screens) { font-size: 16px }
}

@mixin b4s-body($color:white, $weight:400){
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: $weight;
    color: $color;
    @include respond-to(tablet-screens) { font-size: 14px }
}

@mixin b4s-body-small($color:white, $weight:400){
    font-family: 'Poppins';
    font-size: 14px;
    font-weight:$weight;
    letter-spacing: -0.35px;
    color: $color;
    @include respond-to(tablet-screens) { font-size: 12px }
}




/* Styles */
@mixin b4s-icon-primary() {
    @include b4s-pink-grad-2();
    background-clip: text;
    text-shadow: 0 2px 6px rgba($b4s-pink-3, .5);
    display: initial;
    -webkit-text-fill-color: transparent;
    padding-right: 1px;
}

@mixin b4s-icon-dark() {
    @include b4s-blue-grad-1();
    background-clip: text;
    text-shadow: 0 2px 6px rgba($b4s-blue-3, .5);
    display: initial;
    -webkit-text-fill-color: transparent;
    padding-right: 1px;
}
