@import './all';

@tailwind base;
@tailwind components;
@tailwind utilities;

//This file contains rules that affects the web application window, outside of react's jurisdiction

html{
    height: 100%;
    font-family: 'Poppins', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

body{
    height: 100%;   
    display: flex;  /*enables flex content for its children*/
    box-sizing: border-box;
}

#root{
    width:100%;
}
