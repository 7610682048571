@import '../../assets/styles/all';

.group{
    display:flex;
    flex-direction: column;
    margin-bottom:20px;
    flex:1;
    
    .error{
        @include b4s-body-small($b4s-pink-1, 400);
        margin-top:15px;
        display: block;
    }
    
    .error .errorIcon{
        margin-right:5px;
    }

    .input{
        height: 55px;
        border-radius: 5px;
        border:0;
        box-shadow: 1px 1px 3px 1px rgba(150, 156, 197, 0.4);
        text-indent: 20px;
        @include b4s-body-small($b4s-blue-2, 400);
        &::placeholder{
            @include b4s-body-small($b4s-blue-3, 400);
            opacity:1;
        }
    }

    .textarea{
        height: 120px;
        border-radius: 5px;
        border:0;
        box-shadow: 1px 1px 3px 1px rgba(150, 156, 197, 0.4);
        padding: 0 20px;
        box-sizing: border-box;
        padding-top: 15px;
        @include b4s-body-small($b4s-blue-2, 400);
        &::placeholder{
            @include b4s-body-small($b4s-blue-3, 400);
            opacity:1;
        }
    }

    .select{
        height: 55px;
        border-radius: 5px;
        border:0;
        box-shadow: 1px 1px 3px 1px rgba(150, 156, 197, 0.4);
        text-indent: 20px;
        @include b4s-body-small($b4s-blue-2, 400);
        &::placeholder{
            @include b4s-body-small($b4s-blue-3, 400);
            opacity:1;
        }
    }
}

.last{
    margin-bottom:40px;
}
