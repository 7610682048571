@import '../../../../assets/styles/all';

.userMenu{
    position: absolute;
    margin-top: 15px;
    margin-left: -115px;
    padding: 10px 20px;
    li, li a{
        list-style: none;
        color: $b4s-blue-2;
        text-decoration: none;
        line-height: 30px;
        cursor: pointer;
    }
}

@include respond-to(tablet-screens) {
    .userMenu{
        margin-left:-100px;
    }
}
